.avenir {
    height: 13em;
    background: linear-gradient(180deg, rgba(244, 240, 240, 0) 0%, rgba(251, 247, 247, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(246, 246, 246, 0.5) 100%);
    background-color: steelblue;
    margin-inline: 3%;
    border-radius: 15px;
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5px;

    div {
        display: flex;

        img {
            width: 4em;
        }

        p {
            font-size: 1.2em;
        }
    }

    .phone
     {
        width: 60%;
height: 10em; 
   }
}

@media (max-width: 767px)
{
    .avenir {
    height: 8em;
    

    div {
        display: flex;

        p {
            font-size: 1em;
        }
    }

    .phone {
        width: 65%;
height: 4.4em; 
   }
}
}
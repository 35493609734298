.live {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(244, 240, 240, 0) 0%, rgba(251, 247, 247, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(246, 246, 246, 0.5) 100%);
    background-color: lightsteelblue;
    border-radius: 15px;
    align-items: center;

    h3 {
        color: white;
        background: linear-gradient(180deg, #60c1f8 0%, rgba(251, 247, 247, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(246, 246, 246, 0.5) 100%);
        background-color: midnightblue;
        display: flex;
        justify-content: center;
        margin-inline: 35%;
        margin-block-start: 2%;
        height: 2em;
        align-items: center;
        border-radius: 15px;
        border-radius: 15px;
        transform: translateY(-45px);
        border: 4px solid white;
        margin-bottom: 0;

    }

    .nomatch {
        margin-top: 0;
        align-self: center;
        background-color: red;
        color: white;
        width: 40%;
        text-align: center;
        font-family: permanent marker;
        border-radius: 5px;
        height: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .live__tableau {
        padding: 1%;
        width: 98%;
        gap: 8px;

        a {
            text-decoration: none;
            color: black;
            width: 100%;
        

        .live__match {
            display: grid;
            overflow: hidden;
            grid-template-columns: 10% 20% 5% 30% 5% 20% 10%;
            justify-items: center;
            align-items: center;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(10, 10, 10, 0) 42%, rgba(4, 4, 4, 0.1) 99.99%, rgba(0, 0, 0, 0.5) 100%);
background-color: aliceblue;
border-radius: 10px;

            img {
                height: 2.5em;
                width: 2.5em;
                object-fit: contain;
            }

            .match__score {
                display: flex;
                align-items: center;
gap: 4px;
                span {
                    display: flex;
                    height: 2em;
                    width: 2em;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    border-radius: 5px;
                }

                .winner {
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255 255 255 / 0%) 42%, rgb(0 0 0 / 21%) 99.99%, rgb(255 255 255 / 50%) 100%);
                    background-color: #32b642;
                }

                .looser {
                    background: linear-gradient(0deg, rgb(0 0 0 / 0%) 0%, rgba(10, 10, 10, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(0, 0, 0, 0.5) 100%);
                    background-color: #ff2e2e;
                }

                .nul {
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(10, 10, 10, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(0, 0, 0, 0.5) 100%);
                    background-color: #919090;
                }

                .time {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                }


            }

            .sticker__live {
                color: white;
                background: red;
                font-family: permanent marker;
                width: 6em;
                text-align: center;
                transform: rotate(45deg) translateX(10px) translateY(-20px);
                font-size: 0.9em;
            }
        }
    }

    }
}
.ligne__match {
    grid-template-columns: 8% 29% 8% 5% 8% 29% 13%;
        display: grid;
            overflow: hidden;
            justify-items: center;
            align-items: center;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(10, 10, 10, 0) 42%, rgba(4, 4, 4, 0.1) 99.99%, rgba(0, 0, 0, 0.5) 100%);
            padding-block: 5px;
            border-radius: 10px;
            background-color: aliceblue;


    img {
        height: 2.5em;
        width: 2.5em;
        object-fit: contain;
    }

    .match__score {
        display: flex;
        align-items: center;
gap: 4px;
        span {
            display: flex;
            height: 2em;
            width: 2em;
            justify-content: center;
            align-items: center;
            color: white;
            border-radius: 5px;
        }

        .winner {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255 255 255 / 0%) 42%, rgb(0 0 0 / 21%) 99.99%, rgb(255 255 255 / 50%) 100%);
            background-color: #32b642;
        }

        .looser {
            background: linear-gradient(0deg, rgb(0 0 0 / 0%) 0%, rgba(10, 10, 10, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(0, 0, 0, 0.5) 100%);
            background-color: #ff2e2e;
        }

        .nul {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(10, 10, 10, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(0, 0, 0, 0.5) 100%);
            background-color: #919090;
        }

        .time {
            display: flex;
            flex-direction: column;
            align-items: center;

        }


    }

}
.match__rdv {
    display: flex;
                align-items: center;
                background-color: black;
                color: white;
                padding: 2px;
                border-radius: 5px;
}




@media (max-width: 767px) {

    .live {

        .nomatch {
            width: 70%;
        }

        h3 {
            height: 1.7em;
            transform: translateY(-25px);

        }

        .live__tableau {

            a {

            .live__match {
                grid-template-columns: 8% 25% 9% 18% 9% 25% 6%;
                p {
                    font-size: 0.7em;
                }

                .match__logoDom, .match__logoExt {
                    height: 1.6em;
                    width: 1.6em;
                }

                .match__competition {
                    height: 2em;
                    width: 1.8em;
                }

                .match__score {

                    
                    span {
                        height: 1.7em;
                        width: 1.5em;
                    }

                    .time{

                        em {
                            font-size: 10px;
                        }
                    }
                }
.sticker__live {
    transform: rotate(45deg) translateX(-14px) translateY(-6px);
}

            }
            .ligne__match {
                p {
                    font-size: 0.7em;
                }

                .match__logoDom, .match__logoExt {
                    height: 1.6em;
                    width: 1.6em;
                }

                .match__competition {
                    height: 2em;
                    width: 1.8em;
                }

                .match__score {

                    
                    span {
                        height: 1.7em;
                        width: 1.5em;
                    }

                    .time{

                        em {
                            font-size: 10px;
                        }
                    }
                }

            }
        }
        }
    }
}

.rdv {

    font-size: 10px;
    display: flex;
flex-direction: column;    /* justify-content: center; */
    text-align: center;
}

.titreToday {
    width: 50%;
    font-size: 1em;
}

.titreLive {
width:40% ;
}
.match {
    display: grid;
    grid-template-columns: 6% 30% 8% 18% 8% 30%;
    justify-items: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(10, 10, 10, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(0, 0, 0, 0.5) 100%);

    .dateheure {
        display: flex;
        flex-direction: column;

        
em {
    font-size: 14px;
}
    }

    img {
        height: 2.5em;
        width: 2.5em;
        object-fit: contain;
    }

    .match__score {
        display: flex;
        width: 100%;
        justify-content: space-evenly;

        span {
            display: flex;
            height: 2em;
            width: 2em;
            justify-content: center;
            align-items: center;
            color: white;
            border-radius: 5px;
            font-weight: 500;
        }

        .winner {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255 255 255 / 0%) 42%, rgb(0 0 0 / 21%) 99.99%, rgb(255 255 255 / 50%) 100%);
            background-color: #32b642;
        }

        .looser {
            background: linear-gradient(0deg, rgb(0 0 0 / 0%) 0%, rgba(10, 10, 10, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(0, 0, 0, 0.5) 100%);
            background-color: #ff2e2e;
        }

        .nul {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(10, 10, 10, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(0, 0, 0, 0.5) 100%);
            background-color: #919090;
        }
    }

    .modal {
        background-color: red;
    opacity: 1;
    height: 70%;
    width: 80%;
    position: fixed;
    top: 8em;
    }
}

@media (max-width: 767px) {
    .match {
        grid-template-columns: 5% 31% 9% 15% 9% 31%;

        .dateheure {

            em {
                font-size: 8px;
                font-style: normal;
            }
        }

        p {
            font-size: 0.75em;
        }

        img {
            height: 1.35em;
            width: 1.35em;
        }

        .match__score {

            span {
                height: 1.25em;
                width: 1.25em;
                font-size: 0.8em;
                padding: 3px;
            }
        }
    }
}
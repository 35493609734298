.blocpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 96%;
    padding-inline-start: 2%;
    gap: 2em;
    margin-top: 1em;

    .banner {
        height: 16em;
        border-radius: 15px;
        width: 100%;
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 0 -68px;
            border-radius: 15px;
            filter: brightness(0.5);
            transition: opacity 0.2s ease-in-out;
            opacity: 0.6;

        }

        .banner__text {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            bottom: 90%;
            color: white;
            font-size: 2em;
            line-height: 0;

            h1 {
                font-weight: 900;

            }

            h2 {
                font-weight: 900;
                font-size: 1.3em;

            }
        }
    }
}

.banner img.active {
    opacity: 1;
    transition-duration: 0.6s;
}

@media (max-width: 767px) {
    .blocpage {
        .banner {
            height: 8em;

            img {
                object-position: 0 -25px;
            }

            .banner__text {

                h1 {
                    font-size: 1em;
                }

                h2 {
                    font-size: 0.6em;
                }
            }
        }
    }
}
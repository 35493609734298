.blocFicheSelections {
    margin-top: 1em;
    width: 98%;
    margin-inline: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tableaux {

        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 1em;
        justify-content: center;

        .groupe {

            width: 46%;
            display: flex;
            align-items: center;
            flex-direction: column;
            background: linear-gradient(180deg, #000000 0%, rgba(251, 247, 247, 0) 25%, rgba(4, 4, 4, 0.205) 99.99%, rgba(246, 246, 246, 0.5) 100%);
            background-color: steelblue;
            padding: 3px;
            border-radius: 10px;

            h3 {
                margin-block-end: 0.2em;
                margin-block-start: 0.4em;
                color: white;
            }

            .barre {
                display: grid;
                font-size: 0.8em;
                grid-template-columns: 51% 9% 10% 10% 10% 10%;
                width: 100%;
                justify-items: center;
                background-color: lightsteelblue;
                border-radius: 2px;
            }

            .liste {
                padding-inline-start: 0;
                list-style-type: none;
                width: 100%;
                margin-block-start: 0.2em;
                margin-block-end: 0.2em;

                .equipe {
                    display: flex;
                    align-items: center;
                    font-size: 0.8em;
                    display: grid;
                    grid-template-columns: 3% 11% 37% 9% 10% 10% 10% 10%;
                    justify-items: center;
                    border-bottom: 1px solid;
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(10, 10, 10, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(0, 0, 0, 0.5) 100%);
                    background-color: white;

                    p {
                        justify-self: flex-start;
                        margin-left: 5px;
                        font-size: 0.9em;
                    }

                    .flags {
                        height: 1.5em;
                        width: 1.5em;
                        object-fit: cover;
                        border-radius: 50%;
                    }

                    span {
                        font-weight: 600;
                    }

                }
            }
        }
    }
}
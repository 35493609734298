.blocApropos {
    display: flex;
    justify-content: center;

    article {
        width: 58%;
        padding-inline: 3%;

    }
}

@media (max-width: 767px){

    .blocApropos {

        article {
            width: 86%;
            text-align: justify;
        }
    }
}
footer {

    background-color: black;
    height: 7em;
    margin-top: 1em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    color: white;
    align-items: center;
    justify-items: center;
    padding: 0.5em;

    a {
        color: white;
    }

    div {
        display: flex;
    flex-direction: column;
    align-items: center;

    img {
        filter: invert(1);
        width: 4.5em;
    }

    p {
        font-size: 0.8em;
    }
    }
}

@media(max-width: 767px){
    footer {
        display: flex;
    flex-direction: column;
    gap: 2em;
    height: 16em;

    }
}
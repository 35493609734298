.blocJoueur {
    display: flex;
    flex-direction: column;
    width: 98%;
    align-items: center;
    margin-inline: 1%;
    margin-top: 1em;

    article {
        display: flex;
        flex-direction: column;
        width: 90%;

        .infosJoueur {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.19) 25%, rgba(0, 0, 0, 0) 18%, rgba(4, 4, 4, 0.205) 23.99%, rgba(0, 0, 0, 0.64) 100%);
            background-color: steelblue;
            border-radius: 15px;
            display: flex;
            flex-direction: row;
            padding: 5px;
            width: 100%;
            color: white;

            .infosJoueur__photo {
                border-radius: 50%;
                padding-left: 7px;
                height: 6em;
                width: 6em;
                align-self: center;
            }

            .infosJoueur__bio {
                padding-inline-start: 5px;
                display: flex;
                width: 100%;
                justify-content: space-around;

                h3 {
                    margin-block: 0;
                    font-size: 1em;
                    font-family: permanent marker;
                    font-weight: 400;
                }

                p {
                    font-size: 0.8em;
                    margin-block: 0;
                }

                .logos {
                    display: flex;
                    gap: 1em;
                    align-self: center;
                    margin-block: 0.5em;
                    flex-direction: column;

                    .logo {
                        height: 2.3em;
                        width: 2.8em;
                        object-fit: contain;
                    }
                }
            }
        }

        .palmares {
            width: 100%;
            margin-bottom: 1em;

            .palmares__titre {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.19) 48%, rgba(0, 0, 0, 0) 47%, rgba(4, 4, 4, 0.205) 23.99%, rgba(0, 0, 0, 0.64) 100%);
                background-color: steelblue;
                color: white;
                text-align: center;
                margin-block-end: 0;
                border-radius: 5px;
                font-family: permanent marker;
                margin-block-start: 0.5em;
                display: flex;
    align-items: center;
    justify-content: center;
    height: 2em;

    i {
        position: relative;
        left: 100px;
    }
            }

            .palmares__infos {
                    width: 100%;
                    margin-top: 4%;
                    transition-duration: 0.4s;
                    animation: slidein 0.4s ease-in-out;
                    padding-inline-start: 0;
                    list-style-type: none;
                
            }

            .ferme {
                animation: slideout 0.4s ease-in-out;
    display: none;
            }
        }

        .statsJoueur {
            width: 100%;

            h4 {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.19) 48%, rgba(0, 0, 0, 0) 47%, rgba(4, 4, 4, 0.205) 23.99%, rgba(0, 0, 0, 0.64) 100%);
                background-color: steelblue;
                color: white;
                text-align: center;
                margin-block-end: 0;
                border-radius: 5px;
                font-family: permanent marker;
                margin-block-start: 0.5em;
            }

            .liste {
                display: flex;
                justify-content: space-between;

                ul {
                    padding-inline-start: 0;
                    list-style-type: none;
                    margin-block-start: 0.5em;
                    line-height: 2em;

                    li {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .jaune,
                        .rouge {
                            filter: drop-shadow(-1px 0px 1px black);
                        }

                    }
                }

                .logoCompet {
                    height: 4em;
                    margin-top: 10px;
                }
            }
        }
    }
}

@keyframes slidein {
    0% {
        transform: translateY(-60%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }

}

@keyframes slideout {
    0% {
        display: block;
    }

    100% {
        transform: translateY(-80%);
        opacity: 0;
    }
}
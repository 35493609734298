.blocMenu {
    display: flex;
    justify-content: flex-end;
    margin-inline: 1%;
}

.navMenu {
    width: 28px;
    height: 100%;


    &:hover {
        cursor: pointer;
    }
}

.navMenu>span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 9999px;
    background-color: white;



}

.navMenu>span:not(:last-child) {
    margin-bottom: 7px;
}

.navMenu,
.navMenu>span {
    transition: all 0.5s ease-in-out;
}


.navMenu.active {
    transition-delay: 0.8s;
    transform: rotate(45deg);

}

.navMenu.active>span:nth-child(2) {
    opacity: 0;
}

.navMenu.active>span:nth-child(1),
.navMenu.active>span:nth-child(3) {
    transition-delay: 0.5s;
}

.navMenu.active>span:nth-child(1) {
    transform: translateY(9px);
}

.navMenu.active>span:nth-child(3) {
    transform: translateY(-9px) rotate(90deg);
}

.menu {
    height: 3em;
    width: 28em;
    transform: translateX(-85%);
    animation: drop 0.9s ease-in-out;
    position: absolute;


    ul {
        list-style-type: none;
        display: flex;
        gap: 5%;


        a {
            text-decoration: none;



            li {
                color: white;
                transition-duration: 0.5s;
                transform: scale(1);
                font-weight: 500;

                &:hover {
                    text-shadow: 0px 0px 10px white;
                    transform: scale(1.1);
                }
            }
        }

        .active {
            text-decoration: underline;
            color: black;
        }
    }
}


.inactive {
    animation: close 0.9s ease-in-out;
    display: none;
}

@keyframes drop {
    0% {
        transform: translateX(90%);
        opacity: 0;

    }

    50% {

        opacity: 0;

    }


}

@keyframes close {
    0% {
        opacity: 1;
        display: flex;

    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translateX(90%);
        opacity: 0;
    }
}

@media (max-width: 767px) {
    .menu {
        width: 10em;
        transform: translateX(-102%) translateY(-35px);
        animation: drop2 0.8s ease-in-out;

        ul {
            padding-inline-start: 0;
            flex-wrap: wrap;
            gap: 2em;

            li {
                font-size: 14px;
            }
        }
    }
}

@keyframes drop2 {
    0% {
        opacity: 0;

    }

    20%{
        opacity: 0;
    }

    50% {

        opacity: 0.1;

    }


}
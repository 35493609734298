.bloc{

.evenements {
    width: 85%;

    .events {
gap: 1.5em;
font-size: 1.4em;

p {
    font-size: 0.9em;
}

    .domicile {
        display: flex;
        /* align-self: flex-start; */
        width: 50%;
        transform: translateX(-48%);
        justify-content: flex-end;
        gap: 0.4em;

       
    }

    .exterieur {
        display: flex;
    width: 50%;
    transform: translateX(48%);
    gap: 0.4em;

    .subst {
        transform: scaleX(-1);
    }
        
    }

    .goal {
        display: flex;
        flex-direction: column;

        .buteur {
            margin-block: 0;
        }

        .passeDec {
margin-block: 0;
            font-size: 0.7em;
        }
    }

    span {
        display: flex;
        align-items: center;
        gap: 3px;
    }

    em {
        align-self: center;
        background-color: black;
        color: white;
        border-radius: 50%;
        width: 2em;
        display: flex;
        justify-content: center;    }
.changement {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 0;
    margin-block: 0.3em;

    p {
        margin-block: 0.5em;
        font-size: 0.8em;
    }

        .subst {
            height: 26px;
            width: 36px;
        }
    }
}
}

.fiche__selecteur {

    ul {
        display: flex;
        list-style-type: none;
        padding-inline-start: 0;
        background: linear-gradient(0deg, #000000 0%, rgba(251, 247, 247, 0) 30%, rgba(4, 4, 4, 0.205) 99.99%, rgba(246, 246, 246, 0.5) 100%);
        background-color: darkgrey;
        border-radius: 5px;
        height: 2em;
        align-items: center;

        li {
            display: flex;
            width: 6em;
            justify-content: center;
            height: 100%;
            align-items: center;

            &:hover {
                cursor: pointer;
            }

        }

        .selected {
            box-shadow: 0px 0px 5px 5px #524d4d inset;
            background: linear-gradient(0deg, #ffffff 0%, rgba(251, 247, 247, 0) 42%, rgb(255 255 255 / 80%) 99.99%, rgb(0 0 0) 100%);
        }

        .liveButton {
            border-radius: 5px 0px 0px 5px;
        }

        .composButton {
            border-inline: 1px solid;
        }
        
        .detailsButton {
            border-radius: 0px 5px 5px 0px;
        }
    }
}
}

@media (max-width: 767px)
{
    .bloc {

        .evenements {
            width: 90%;

            .events {
                font-size: initial;
                
                .domicile {
                    transform: translateX(-40%);

                }

                .exterieur {
                    transform: translateX(40%);

                }
            }
        }
    }
}
.blocContact {

    display: flex;
    flex-direction: column;
    align-items: center;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;
        width: 50%;

        .formulaire__background {

            background: linear-gradient(180deg, #000000 0%, rgba(251, 247, 247, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(246, 246, 246, 0.5) 100%);
    background-color: midnightblue;
    width: 100%;
    height: 20em;
    padding-block: 1em;
    color: white;
    border-radius: 10px;
    display: flex;
    gap: 1em;
    flex-direction: column;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            input {
                border-radius: 5px;
                height: 2em;
            }

            textarea {
                width: 25em;
    height: 8em;
    border-radius: 5px;
            }
        }
    }

        .submit {
            height: 2em;
    background: linear-gradient(0deg, #000000 0%, rgba(251, 247, 247, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(246, 246, 246, 0.5) 100%);
    background-color: midnightblue;
    font-family: kanit;
    color: white;
    transition-duration: 0.4s;
    border-radius: 5px;
    width: 10em;
    transform: scale(1);

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
       background:  linear-gradient(0deg, #000000 0%, rgba(251, 247, 247, 0) 42%, rgb(255 255 255 / 58%) 99.99%, rgba(246, 246, 246, 0.5) 100%);
        background-color: midnightblue;
    }
        }
    }
}

@media (max-width: 767px) {

    .blocContact {

        form {
            width: 80%;

            .formulaire__background {

                div {

                textarea {
                    width: 18em;
                }
            }
            }
        }
    }
    
}
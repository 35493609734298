header {
    background: linear-gradient(0deg, #000000 0%, rgba(251, 247, 247, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(246, 246, 246, 0.5) 100%);
    background-color: midnightblue;
    height: 10em;
    display: flex;
    align-items: center;
    padding-inline: 2%;
    justify-content: space-between;

    img {
        width: 7em;
        height: 7em;
        border-block: 5px solid white;
        margin: 1%;
    }
}

@media (max-width: 767px){

    header {

        img {
            width: 5em;
            height: 5em;
        }
    }
}
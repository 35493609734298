.competitions {
    display: flex;
    flex-direction: column;
    gap: 3em;
    padding: 1%;
    width: 98%;

    .conteneur {
        display: flex;
        flex-direction: column;
        background: linear-gradient(180deg, rgb(244 240 240 / 0%) 0%, rgb(251 247 247 / 0%) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgb(246 246 246 / 50%) 100%);
        background-color: lightsteelblue;
        border-radius: 15px;

        .title {
            color: white;
            background: linear-gradient(180deg, #60c1f8 0%, rgba(251, 247, 247, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(246, 246, 246, 0.5) 100%);
            background-color: midnightblue;
            display: flex;
            justify-content: center;
            margin-inline: 35%;
            margin-block-start: 2%;
            height: 3em;
            align-items: center;
            border-radius: 15px;
            border-radius: 15px;
            transform: translateY(-50px);
            border: 4px solid white;
            align-self: center;
            width: 30%;
        }


        .filtres {
            display: flex;
    justify-content: center;
    gap: 2em;
    padding-bottom: 2%;
    flex-wrap: wrap;

            a {
                width: 14%;
                display: flex;
                justify-content: flex-start;
                border: 10px solid transparent;
                border-radius: 20px;
                padding-block-start: 1%;
                flex-direction: column-reverse;
                align-items: center;
                text-decoration: none;
                height: 9em;
                background: linear-gradient(1800deg, rgb(235, 235, 235) 100%, rgba(251, 0, 0, 0.15) 24%, rgba(255, 255, 255, 0.7) 99.99%, rgba(0, 0, 0, 0.88) 92%) padding-box, linear-gradient(180deg, #2c57ab, #000000) border-box;
                background-color: aliceblue;

                article {
                    display: flex;
                    justify-content: center;

                    img {
                        width: 5em;
                        height: 5em;
                    }
                }

                h4 {
                    color: white;
                    background-color: #19721b;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 2em;
                    margin-block-end: 1em;
                    margin-top: 15%;
                    font-family: permanent marker;
                    font-weight: normal;
                }

                .europeText {
                    color: white;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-block: 0.8em;
                    height: 3em;
                    text-align: center;
                }
            }

            .lienEurope {
                justify-content: center;
            }
        }


    }
}

.blocClubs {
    margin-block-start: 2em;

    .competitions {

        .conteneur {

            .filtres {

                a {

    h4 {
        margin-block-end: 0;
    }
}
}
}
}
}

.blocSelections {
    margin-block-start: 2em;

    .competitions {

        .conteneur {

            .filtres {

                a {
                    width: initial;

                    h4 {
                        font-size: 0.9em;
                    }
                }

            }
        }
    }
}

.flag {
    width: 1.7em;
    height: 1.2em;
    transform: rotate(-10deg);
}

@media (max-width: 1024px){

    .competitions {
        .conteneur {
    
            .title {
                text-align: center;
                height: 2.5em;
                width: 35%;
                transform: translateY(-40px);

                h3 {
                    font-size: 1.05em;
                }
    
            }
    
            .filtres {
                flex-wrap: wrap;
                gap: 10%;
                width: 100%;
                
    
                a {
                    width: 18%;
                    height: 8em;
                    margin-block: 2%;
    
                    article {
    
                        img {
                            width: initial;
                            height: 4.5em;
                        }
                    }
    
                    h4 {
                        font-size: 0.78em;
                        margin-top: 8%;
                    }
    
                    .europeText {
                        margin-block-start: 0.3em;
                        font-size: 0.64em;
                    }
    
                }
    
                .lienEurope {
                    width: 18%;
                }
            }
        }
    }
    }

    .blocClubs {
        margin-block-start: 2em;
    
        .competitions {
            gap: 2em;
    
            .conteneur {
    
                .filtres {
    
                    a {
    
        h4 {
            margin-block-end: 0;
        }
    }
    }
    }
    }
    }


    
    .flag {
        width: 1.7em;
        height: 1.2em;
        transform: rotate(-10deg);
    }


@media (max-width: 767px){

.competitions {
    gap: 2em;
    .conteneur {
        align-items: center;

        .title {
            width: 16em;
            height: 2em;
            padding: 2px;
            margin-top: 4%;
            transform: translateY(-35px);

        }

        .filtres {
            flex-wrap: wrap;
            gap: 10%;
            width: 100%;
            

            a {
                width: 33%;
                height: 7.6em;
                margin-block: 2%;

                article {

                    img {
                        width: initial;
                        height: 4em;
                    }
                }

                h4 {
                    font-size: 0.78em;
                    margin-top: 8%;
                }

                .europeText {
                    margin-block-start: 0.3em;
                    font-size: 0.64em;
                }

            }

            .lienEurope {
                width: 35%;
            }
        }
    }
}
}

.blocClubs {
    margin-block-start: 2em;

    .competitions {

        .conteneur {

            .filtres {

                a {
                        article {
                    img {
                        height: 4em;
                    }
                }

    h4 {
        margin-block-end: 0;
    }
}
}
}
}
}

.blocSelections {
    margin-block-start: 2em;

    .competitions {

        .conteneur {

            .filtres {

                a {

                    h4 {
                        font-size: 0.72em;
                    }
                }

            }
        }
    }
}

.flag {
    width: 1.7em;
    height: 1.2em;
    transform: rotate(-10deg);
}
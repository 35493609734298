article {
    width: 100%;

    a {
        text-decoration: none;
    }

    strong {
        font-size: 1.2em;
    }

    .compoTitle {
        margin-block-end: 0;
    }

    .joueurs {

        display: flex;
        width: 85%;
        justify-content: space-evenly;

        .headerCompo {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .logoCompo {
            width: 2.5em;
            height: 2.5em;
            object-fit: contain;
        }

        .maillot {
            filter: invert(1);
        }

        p {
            font-size: 1.1em;
            margin-block: 0.2em;
            font-weight: 500;
        }

        h4 {
            margin-block-end: 0;
            margin-block-start: 1em;
        }

        h5 {
            margin-block: 0.5em;
        }
        
        .joueur {
            display: flex;
    flex-direction: column;
    height: 2em;
    justify-content: center;
        }

        .joueur__infos {
            display: flex;
            gap: 0.5em;

            img {
                width: 2em;
                height: 2em;
                filter: drop-shadow(-1px -1px 2px black);
            }
        }
        ul {
            list-style-type: none;
            padding-inline-start: 0;
            margin-block-start: 0;

            .changement {
                display: flex;
                flex-direction: column;
                justify-content: center;
                line-height: 0px;

                em {
                    font-size: 9px;
                }
            }
        }

        .titulaires,
        .remplacants {
            padding: 10px;
        }

        .titulaires {

            a {
                color: white;
            }
        }

        .remplacants {

            a {
                color: black;
            }

            .maillot {
                filter: initial;
            }
        }


        li {
            display: flex;
            gap: 10px;
            height: 3em;
            align-items: center;
    margin-block: 0.5em;


            .fleche {
                width: 1.5em;
                height: 1.5em;
                filter: drop-shadow(0px 0px 1px white);

            }

        }

    }

    .joueurs__domicile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 43%;

        li {
            padding-inline-start: 4px;

        }

        .changement {
            align-items: flex-end;
        }

        .joueur {
            align-items: flex-start;

        }

        .titulaires {
            display: flex;
    flex-direction: column;
    color: white;
    justify-content: flex-start;
    border-radius: 0px 15px 15px 0px;
    background: linear-gradient(0deg, #474646, rgb(0 0 0 / 0%) 114%, rgb(0 0 0 / 89%) 73.99%, rgb(0 0 0 / 50%));
    background-color: #398bd3;

            .changement {
                align-items: flex-end;
            }

            .fleche {
                transform: rotate(180deg);
            }
        }

        .remplacants {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            border-radius: 0px 15px 15px 0px;
            background: linear-gradient(0deg, #C1C1C1, #fbf7f700 10%, rgba(4, 4, 4, .205) 99.99%, #f6f6f680);

            .changement {
                align-items: flex-start;
            }

            .fleche {
                filter: drop-shadow(0px 0px 1px black);

            }
        }
    }

    .joueurs__ext {
        display: flex;
        flex-direction: column;
        text-align: right;
        width: 43%;

        .headerCompo {
            justify-content: flex-end;
        }

        .joueur {
            align-items: flex-end;
        }

        li {
            padding-inline-end: 4px;

        }

        .titulaires {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            border-radius: 15px 0px 0px 15px;
            background: linear-gradient(0deg, #474646, rgb(0 0 0 / 0%) 114%, rgb(0 0 0 / 89%) 73.99%, rgb(0 0 0 / 50%));
    background-color: #398bd3;
    color: white;

            .changement {
                align-items: flex-start;
            }
        }

        .remplacants {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            border-radius: 15px 0px 0px 15px;
            background: linear-gradient(0deg, #C1C1C1, #fbf7f700 10%, rgba(4, 4, 4, .205) 99.99%, #f6f6f680);

            .changement {
                align-items: flex-end;
            }

            .fleche {
                transform: rotate(180deg);
                filter: drop-shadow(0px 0px 1px black);

            }
        }

        li {
            flex-direction: row-reverse;
        }
    }
}

@media (max-width: 767px){

    article {
width: 95%;

strong {
    font-size: 0.9em;
}

        .joueurs {
width: 100%;
justify-content: space-around;

.titulaires,
        .remplacants {
            padding-inline: 0;
            padding-block: 5px;
        }

.joueur__infos {

    img {
        height: 1.5em;
        width: 1.5em;
    }
}

p {
    font-size: 0.78em;

}

li {
    gap: 2px;
            height: 2.5em;
}

        }

        .joueurs__domicile {
width: 49%;
        }

        .joueurs__ext {
width: 49%;
        }
    }
}
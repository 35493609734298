.calendrierEtResultats {
    background: linear-gradient(0deg, rgb(244 240 240 / 0%) 0%, rgb(251 247 247 / 0%) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgb(246 246 246 / 50%) 100%);
    background-color: aliceblue;
    border-radius: 5px;
    margin: 2%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .clearfix {
        display: flex;
        justify-content: center;
        gap: 25%;
        align-items: center;
        width: 50%;

        .head-info select {
            border: none;
            appearance: unset;
            background-color: transparent;
            font-family: permanent marker;
            font-size: 1em;
            color: black;
        }
    
    .pre-button {
        color: steelblue;
        &:hover {
            cursor: pointer;
        }
    }

    .next-button {
        color: steelblue;
        &:hover {
            cursor: pointer;
        }

    }
.fa-solid {
    font-weight: 900;
    width: 3em;
    height: 3em;
    display: flex;
    align-items: center;
    font-size: 1.4em;
    justify-content: center;
}
    }
    h3 {
        background: linear-gradient(0deg, rgb(244 240 240 / 0%) 0%, rgb(251 247 247 / 0%) 42%, rgb(255 255 255 / 21%) 99.99%, rgb(255 251 251 / 50%) 100%);
        background-color: midnightblue;
        color: white;
        text-align: center;
        width: 35%;
        border-radius: 10px;
        margin-block-start: 0;
        height: 2em;
        transform: translateY(-20px);
        border: 4px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tableau {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-inline-start: 0;
        width: 99%;

        a {
            text-decoration: none;
            color: #000000;

        li {
            border-bottom: 1px solid black;

            p {
                font-weight: 500;
            }
        }
    }
    }

   
}

@media (max-width: 767px) {

    .calendrierEtResultats {
        h3 {
            font-size: 0.9em;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
        }
    }
}
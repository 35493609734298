.bloc {
    display: flex;
    flex-direction: column;
    align-items: center;

    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .datelieu {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .affiche {
            display: grid;
            background: linear-gradient(0deg, #000000 0%, rgba(251, 247, 247, 0) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgba(246, 246, 246, 0.5) 100%);
            grid-template-columns: 40% 20% 40%;
            background-color: steelblue;
            color: white;
            padding-top: 1em;
            border-radius: 10px;
            width: 85%;
            margin-block: 5px;

            .domicile,
            .exterieur {
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    height: 9em;
                }
            }

            .score {
                align-self: center;
                height: 3em;
                font-size: 2em;
                justify-self: center;
            }
        }

        .buts {
            display: flex;
            width: 85%;
            justify-content: space-between;
            list-style-type: none;

            li {
                display: flex;
                align-items: center;
                gap: 15px;
                font-size: 1.1em;
            }

            .equipeExt {
                text-align: right;

                li {
                    flex-direction: row-reverse;
                }
            }

        }

        .fiche__selecteur {

            ul {
                display: flex;
                list-style-type: none;
                padding-inline-start: 0;
                background: linear-gradient(0deg, #000000 0%, rgba(251, 247, 247, 0) 30%, rgba(4, 4, 4, 0.205) 99.99%, rgba(246, 246, 246, 0.5) 100%);
                background-color: darkgrey;
                border-radius: 5px;
                height: 2em;
                align-items: center;

                li {
                    display: flex;
                    width: 6em;
                    justify-content: center;
                    height: 100%;
                    align-items: center;

                    &:hover {
                        cursor: pointer;
                    }

                }

                .selected {
                    box-shadow: 0px 0px 5px 5px #524d4d inset;
                    background: linear-gradient(0deg, #ffffff 0%, rgba(251, 247, 247, 0) 42%, rgb(255 255 255 / 80%) 99.99%, rgb(0 0 0) 100%);
                }

                .details {
                    border-radius: 5px 0px 0px 5px;
                }

                .compos {
                    border-inline: 1px solid;
                }
                
                .lives {
                    border-radius: 0px 5px 5px 0px;
                }
            }
        }
    }
}

.blocInfos {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}



@media (max-width: 767px) {

    .bloc {

        section {

            .datelieu {
                font-size: 0.85em;
            }

            .affiche {
                width: 95%;

                .domicile,
                .exterieur {

                    img {
                        height: 6em;
                        width: 8em;
                        object-fit: contain;
                    }

                    p {
                        font-size: 0.9em;
                    }
                }

                .score {
                    font-size: 1.75em;

                }
            }

            .buts {

                width: 95%;

                li {
                    font-size: 0.85em;
                    gap: 5px;
                }
            }
        }
    }
}
article {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;


    .statistiques {

        display: flex;
        flex-direction: column;
        width: 85%;

        div {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            background: linear-gradient(0deg, #000000 0%, rgb(0 0 0 / 0%) 70%, rgba(4, 4, 4, 0.205) 99.99%, rgb(0 0 0 / 50%) 100%);
            background-color: steelblue;
            color: white;
            border-bottom: 1px solid;
            font-size: 1.3em;
        }
    }
}

@media(max-width: 1024px) {
    article {

        .statistiques {
            width: 88%;

            div {
                font-size: 1.2em;
            }
        }
    }
}

@media (max-width: 767px) {

    article {

        .statistiques {
            width: 100%;

            div {
                justify-content: space-around;
                font-size: initial;
            }
        }

    }
}
.classementEtStats {
    display: flex;
    gap: 2%;
    width: 98%;

    .titreCollapse {
        background: linear-gradient(0deg, rgb(244 240 240 / 0%) 0%, rgb(251 247 247 / 0%) 42%, rgb(255 255 255 / 21%) 99.99%, rgb(255 251 251 / 50%) 100%);
        background-color: midnightblue;
        color: white;
        text-align: center;
        width: 55%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2em;
        padding-inline: 5%;
        &:hover {
            cursor: pointer;
        }

        .titreCollapse__logo {
            width: 1.5em;
            height: 1.5em;
        }
    }

    .classement {
        width: 60%;
        background: linear-gradient(180deg, rgb(244 240 240 / 0%) 0%, rgb(251 247 247 / 0%) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgb(246 246 246 / 50%) 100%);
        background-color: aliceblue;
        padding: 1%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        overflow: hidden;

        .barre {
            display: grid;
            grid-template-columns: 7% 40% 7% 7% 7% 7% 7% 7% 11%;
            justify-items: center;
            background: linear-gradient(180deg, rgb(244 240 240 / 0%) 0%, rgb(251 247 247 / 0%) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgb(246 246 246 / 50%) 100%);
            background-color: lightsteelblue;
            width: 100%;
        }

        ul {
            padding-inline-start: 0;
            width: 100%;

            .equipe {
                display: grid;
                grid-template-columns: 7% 10% 30% 7% 7% 7% 7% 7% 7% 11%;
                align-items: center;
                border-bottom: 1px solid black;
                justify-items: center;
                padding-block: 0.5%;
                background: linear-gradient(180deg, rgb(244 240 240 / 0%) 0%, rgb(251 247 247 / 0%) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgb(246 246 246 / 50%) 100%);

                img {
                    width: 2em;
                    height: 2em;
                }
            }
        }
    }

    .statistiques {

        display: flex;
        flex-direction: column;
        width: 40%;
        gap: 2%;
        margin-inline: 2%;

        .statistiques__joueurs {

            width: 100%;
            background: linear-gradient(180deg, rgb(244 240 240 / 0%) 0%, rgb(251 247 247 / 0%) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgb(246 246 246 / 50%) 100%);
            background-color: aliceblue;
            padding: 2%;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 5px;
            overflow: hidden;

            .barreButeurs {
                display: grid;
                grid-template-columns: 64% 14% 18%;
                justify-items: center;
                background: linear-gradient(180deg, rgb(244 240 240 / 0%) 0%, rgb(251 247 247 / 0%) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgb(246 246 246 / 50%) 100%);
                background-color: lightsteelblue;
                width: 100%;
            }

            .barrePasseurs {
                display: grid;
                grid-template-columns: 50% 30% 18%;
                justify-items: center;
                background: linear-gradient(180deg, rgb(244 240 240 / 0%) 0%, rgb(251 247 247 / 0%) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgb(246 246 246 / 50%) 100%);
                background-color: lightsteelblue;
                width: 100%;

            }

            ul {
                padding-inline-start: 0;
                width: 100%;
a {
    text-decoration: none;
    color: black;
    width: 100%;
}

                .buteur {
                    align-items: center;
                    background: linear-gradient(180deg, rgb(244 240 240 / 0%) 0%, rgb(251 247 247 / 0%) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgb(246 246 246 / 50%) 100%);
                    display: grid;
                    grid-template-columns: 64% 14% 18%;
                    padding-inline-start: 2em;
                    justify-items: center;
                    border-bottom: 1px solid black;

                    .joueur__infos {
                        display: flex;
                        flex-direction: column;
                        /* justify-self: center; */
                        width: 100%;
                        margin-block: 3%;


                        .joueur__infos__equipe {
                            display: flex;
                            gap: 2%;
                            align-items: center;

                            img {
                                width: 1em;
                                height: 1em;
                            }

                            p {
                                font-weight: 400;
                                font-size: 0.8em;
                                margin-block: 0;
                            }
                        }
                    }

                }

                .passeur {
                    align-items: center;
                    background: linear-gradient(180deg, rgb(244 240 240 / 0%) 0%, rgb(251 247 247 / 0%) 42%, rgba(4, 4, 4, 0.205) 99.99%, rgb(246 246 246 / 50%) 100%);
                    display: grid;
                    grid-template-columns: 58% 19% 22%;
                    padding-inline-start: 2em;
                    justify-items: center;
                    border-bottom: 1px solid black;

                    .joueur__infos {
                        display: flex;
                        flex-direction: column;
                        /* justify-self: center; */
                        width: 100%;
                        margin-block: 3%;

                        .joueur__infos__equipe {
                            display: flex;
                            gap: 2%;
                            align-items: center;

                            img {
                                width: 1em;
                                height: 1em;
                            }

                            p {
                                font-weight: 400;
                                font-size: 0.8em;
                                margin-block: 0;
                            }
                        }
                    }

                }
            }
        }
    }

    span {
        font-weight: bold;
    }

    p {
        font-weight: 500;
        justify-self: flex-start;
    }

    ul {
        margin-top: 0.5em;
    }
}

.dropdown {
    width: 100%;
    margin-top: 4%;
    transition-duration: 0.4s;
    animation: slidein 0.4s ease-in-out;
}

.inactive {
    animation: slideout 0.4s ease-in-out;
    display: none;
}

.fa-chevron-down {
    transition: transform 0.5s cubic-bezier(1, -1.3, .46, 1.82);
}

.actif {
    transform: rotate(180deg);
    color: white;
}

@keyframes slidein {
    0% {
        transform: translateY(-60%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }

}

@keyframes slideout {
    0% {
        display: block;
    }

    100% {
        transform: translateY(-80%);
        opacity: 0;
    }
}

@media (max-width:1024px){

    .classementEtStats {

        .titreCollapse {
            width: 69%;

            h3 {
                font-size: 1.05em;
            }

            .titreCollapse__logo {
                width: 1.4em;
                height: 1.4em;
            }
        }
    }

}

@media (max-width: 767px) {

    .classementEtStats {
        flex-direction: column;
        width: 100%;
        margin: 2%;

        .classement {
            width: 100%;
            padding: 0%;
            padding-block: 1%;

            .barre {
                font-size: 0.8em;
                width: 98%;
                margin-inline: 1%;
            }

            ul {
                width: 98%;
                margin: 1%;

                .equipe {
                    font-size: 0.8em;
                    padding-block: 0;
                }
            }
        }

        .statistiques {
            width: 100%;
            margin: 0;

            .statistiques__joueurs{
                padding: 0%;
                padding-block: 1%;
                margin-top: 2%;

            .titreCollapse {

            .titreCollapse__logo {
                width: 1.2em;
                height: 1.2em;
            }
        }

        .barreButeurs {
            font-size: 0.8em;
            width: 98%;
            margin-inline: 1%;
        }

        .barrePasseurs {
            font-size: 0.8em;
            width: 98%;
            margin-inline: 1%;
        }

        ul {
            width: 98%;
            margin-inline: 1%;
        }
    }

        }

        h3 {
            font-size: 1em;
        }


        li {
            font-size: 0.8em;
        }
    }
}